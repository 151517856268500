import logo from "./drumsticks_white.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="drum sticks icon" />

        <h1>Coming Soon</h1>

        <p>
          In the meantime, why not visit{" "}
          <a
            className="App-link"
            href="https://finlaypanter.yolasite.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            finlaypanter.yolasite.com
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
